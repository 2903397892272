import dayjs from "dayjs";

export const times = (type) => {
    let datas;

    // 今日: 0
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    // other
    if(type===-2){
        datas = new Date(Date.now() - (3600 * 1000 * 24 * 2));
    }else if(type===-1){
        datas = new Date(Date.now() - (3600 * 1000 * 24 * 1));
    }else if(type===0){
        datas = new Date(Date.now());
    }else{
        datas = new Date(Date.now() - (3600 * 1000 * 24 * (+type-1)));
    }
    const years = datas.getFullYear();
    const months = datas.getMonth() + 1;
    const days = datas.getDate();
  
    let arr = []
    let start = years + '-' + months + '-' + days + ' 00:00:00';
    let end = year + '-' + month + '-' + day + ' 23:59:59';
    if(type===-2 || type===-1){
        end = years + '-' + months + '-' + days + ' 23:59:59';
    }
    // start = +new Date(start)/1000
    start = dayjs(start).unix()

    // end = +new Date(end)/1000
    end = dayjs(end).unix()
    arr = [start, end]

    return arr;
  };