import http from "utils/request";
import payHttp from "utils/payrequest";

// topBanner
export const Top = (params = {}) => {
  return http.get("/api/wap/top", { params });
}; 
// nav
export const Navigation = (params = {}) => {
  return http.get("/api/wap/navigation", { params });
}; 
// 盲盒
export const GetInterestGoods = (params = {}) => {
  return http.get("/api/wap/get_interest_goods", { params });
}; 
export const AddInterestGoods = (params = {}) => {
  return http.post("/api/wap/add_interest_goods", params);
}
export const MhShares = (params = {}) => {
  return http.post("/api/member/shares", params);
}

// banner
export const GoodsClass = (params = {}) => {
  return http.get("/api/wap/goods_class", { params });
}; 

export const Template = (params = {}) => {
  return http.get("/api/wap/template", { params });
}; 
export const GetTemplate = (params = {}) => {
  return http.get("/api/wap/get_template", { params });
}; 
export const ClassTop = (params = {}) => {
  return http.get("/api/wap/goods_class_top", { params });
}; 
export const GoodsClassClick = (params = {}) => {
  return http.post("/api/wap/goods_class_click", params);
}
// rank
export const StoreTop = (params = {}) => {
  return http.get("/api/wap/store_top", { params });
};
export const GoodsTop = (params = {}) => {
  return http.get("/api/wap/goods_top", { params });
};
export const GetCharts = (params = {}) => {
  return http.get("/api/wap/get_charts", { params });
};

export const GetStoreRecommend = (params = {}) => {
  return http.get("/api/wap/get_store_recommend", { params });
};

// 以图搜索
export const SearchImgGoods = (params = {}) => {
  return http.post("/api/member/search_img_goods", params);
};
// 统计
export const TemplateExtend = (params = {}) => {
  return http.post("/api/wap/template_extend", params);
};
// 新进商家
export const GetNewlyStore = (params = {}) => {
  return http.get("/api/wap/get_newly_store", { params });
};
// H5跳转链接
export const WxUrl = (params = {}) => {
  return http.post("/api/common/wx_url", params );
};
// 一网打尽
export const SummarySerach = (params = {}, page) => {
  return http.post("/api/wap/summary_serach?limit=80&page=" + page, params );
};
export const SummaryDetails = (params = {}) => {
  return http.post("/api/wap/summary_details", params );
};

// 今日新品sku
export const GoodsSkuCount = (params = {}) => {
  return http.get("/api/wap/goods_sku_count", { params });
};


// 浏览统计
export const AddDataOverview = (params = {}) => {
  return http.post("/api/wap/add_data_overview", params );
};

// 客服邀请
export const KfInvitation = (params = {}) => {
  return payHttp.post("/api/member/kf_invitation", params );
};


// 新首页
export const GetRecommendConfig = (params = {}) => {
  return payHttp.get("/api/wap/get_recommend_config", { params });
};
export const GetNewRecommendConfig = (params = {}) => {
  return http.get("/api/wap/get_new_recommend_config", { params });
};


// 新收缩
export const GetSearchSort = (params = {}) => {
  return payHttp.get("/api/get_search_sort", { params });
};


// 排行榜
export const GetHotStore = (params = {}) => {
  return http.get("/api/wap/get_hot_store", { params });
};


// 实力榜单
export const GetScoreStore = (params = {}) => {
  return http.get("/api/wap/get_score_store", { params });
};



// 搜索页侧边
export const PcAdvAb = (params = {}) => {
  return http.get("/api/wap/pc_adv_ab", { params });
};

// 人气爆款
export const GetPopularityBrowse = (params = {}) => {
  return http.get("/api/wap/get_popularity_browse", { params });
};

// 电商工具
export const GetToolList = params => {
  return payHttp.get('/api/syscore/get_tool_list', { params })
}