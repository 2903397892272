import axios from "axios";
import "config/global";
import Storage from "utils/storage";

const http = axios.create({
  baseURL: global.javaUrl,
  timeout: "60000",
});

// let qpsMap = new Map()
const qpsController = async (config) => {
  const user = Storage.getUser("member");
  const user_info = JSON.parse(user) || {};
  config.headers.Authorization = user_info.token;

  if (config.method === "get") {
    if (config.params.need_page) {
      config.url =
        config.url + "/" + config.params.limit + "/" + config.params.page;
      delete config.params.page;
      delete config.params.limit;
      delete config.params.need_page;
    }
  } else if (config.method === "post") {
    if (config.data.need_page) {
      config.url =
        config.url + "/" + config.data.limit + "/" + config.data.page;
      delete config.data.page;
      delete config.data.limit;
      delete config.data.need_page;
    }
  }

  return config;
};
http.interceptors.request.use(qpsController, (error) => {
  console.log(error);
  return Promise.reject();
});

http.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.status === 204) {
      return response.data;
    } else if (response.status === 401) {
      // localStorage.removeItem("member");
      // window.location.href = "/login";
      return;
    } else {
      Promise.reject();
    }
  },
  (error) => {
    console.log("error", error);
    return Promise.reject();
  }
);

export default http;
