// less
import "./index.less";

// module
import Banner from "./Banner";
import MainBottom from "../../components/MainBottom";

const Module1 = (props) => {
  const { datas } = props;

  const templateFn = (key) => {
    return (datas[key] || { template_common: [] })?.template_common || [];
  };
  return (
    <div className="new_home-module1">
      <div className="module1-top">
        <div className="module1-left">
          <Banner data={templateFn("left_top")} />
          <Banner data={templateFn("left_bottom")} />
        </div>
        <div className="module1-right">
          {templateFn("right")?.map?.((k, i) => (
            <div className="module1-right-item pointer" key={i} onClick={()=>{
              window.open(k.template_url)
            }}>
              <img src={k.template_img} alt="" />
              <div className="template-info">
                <div className="store-name owt">{k.store_name}</div>
                <div className="template-name owt">{k.template_name}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="new_home_main_bottom">
        <MainBottom data={templateFn("main_bottom_left")} />
        <MainBottom data={templateFn("main_bottom_right")} />
      </div>
    </div>
  );
};
export default Module1;
