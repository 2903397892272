import { Image } from "antd";

// api
import { GetHotStore } from "api/home";

// css
import "./index.less";

// img
import shop_hot_1 from "assets/new_home/shop_hot_1.png";
import shop_hot_2 from "assets/new_home/shop_hot_2.png";
import shop_hot_3 from "assets/new_home/shop_hot_3.png";
import shop_hot_rank1 from "assets/new_home/shop_hot_rank1.png";
import shop_hot_rank2 from "assets/new_home/shop_hot_rank2.png";
import shop_hot_rank3 from "assets/new_home/shop_hot_rank3.png";
import shop_hot_rank4 from "assets/new_home/shop_hot_rank4.png";
import { useEffect, useState } from "react";
import { message } from "antd";

const title_img = {
  1: shop_hot_1,
  2: shop_hot_2,
  3: shop_hot_3,
};
const icon_img = {
  1: shop_hot_rank1,
  2: shop_hot_rank2,
  3: shop_hot_rank3,
  4: shop_hot_rank4,
};

const ShopRank = () => {
  const [countArr, setCountArr] = useState({});

  const getHotStore = async () => {
    const res = await GetHotStore({});
    if (!res) return;
    if (res.ResponseStatus) return message.error(res.ResponseMsg);
    setCountArr(res?.datas || {});
  };
  useEffect(() => {
    getHotStore();
  }, []);

  return (
    <div className="new-home-shop_rank">
      {["day_list", "week_list", "months_list"]?.map?.((k, i) => (
        <div className="shop_rank_items" key={i}>
          <div className="shop_rank_items_title">
            <img src={`${title_img?.[i + 1]}`} alt="" />
          </div>
          {countArr?.[k]?.map?.((item, index) => (
            <div className="shop_rank_item" key={index}>
              <div className="flex-center">
                <div className="icon">
                  <img src={icon_img?.[index < 4 ? index + 1 : 4]} alt="" />
                  <span className="icon-rank">{index + 1}</span>
                </div>
                <Image
                  className="store_logo pointer"
                  src={item.store_logo+"?x-oss-process=image/resize,m_lfit,h_60,w_60/quality,q_55"}
                  preview={false}
                  // onClick={() => {
                  //   window.open(`/merchant/${item.store_id}`);
                  // }}
                  fallback="https://osss.fangzhifu.com/zyt.jpeg"
                />
                <span
                  className="store_name pointer"
                  // onClick={() => {
                  //   window.open(`/merchant/${item.store_id}`);
                  // }}
                >
                  {item.store_name}
                </span>
              </div>
              <div className="count">{item.sales}件</div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
export default ShopRank;
