import http from "utils/request";
import payhttp from "utils/payrequest";

export const SearchGoods = (params = {}) => {
  return http.get("/api/wap/search_goods", { params });
}; 
export const SearchGoodsSku = (params = {}) => {
  return http.get("/api/wap/search_goods_sku", { params });
};
export const GetSearchimgClass = (params = {}) => {
  return payhttp.get("/api/get_searchimg_class", { params });
};
export const GetSearchImgGoods = (params = {}) => {
  return http.get("/api/wap/get_search_img_goods", { params });
};




export const SearchImgUrl = (params = {}) => {
  return http.post("/api/member/search_img_url", params );
}; 
export const GetKeyWord = (params = {}) => {
  return http.get("/api/member/get_keyword", { params });
}; 
export const AddKeyWord = (params = {}) => {
  return http.post("/api/member/add_keyword", params );
}; 
export const DelKeyWord = (params = {}) => {
  return http.post("/api/member/del_keyword", params );
}; 