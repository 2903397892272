import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper/core";
SwiperCore.use([Autoplay, Pagination, Navigation]);

const SearchLeft = (props) => {
  const { datasLeft } = props;

  return (
    <div className="new_home-search-left">
      {datasLeft?.length === 1 ? (
        <div
          className={
            datasLeft?.[0]?.template_url
              ? "pointer swiper-slide"
              : "swiper-slide"
          }
          style={{
            height: "100%",
            background: `url(${datasLeft?.[0]?.template_img}) no-repeat 0 / 100%`,
            backgroundColor: "#f6f6f6",
          }}
          onClick={() => {
            if (!datasLeft?.[0]?.template_url) return;
            window.open(datasLeft?.[0]?.template_url);
          }}
        ></div>
      ) : (
        <Swiper
          slidesPerView={1}
          spaceBetween={0}
          loop
          loopAdditionalSlides={2}
          loopedSlides={3}
          autoplay
          className="mySwiper"
          observeParents
          observer
        >
          {datasLeft?.map?.((k, i) => (
            <SwiperSlide key={i}>
              <div
                className={k.template_url ? "pointer" : ""}
                style={{
                  height: "100%",
                  background: `url(${k.template_img}) no-repeat 0 / 100%`,
                  backgroundColor: "#f6f6f6",
                }}
                onClick={() => {
                  if (!k.template_url) return;
                  window.open(k.template_url);
                }}
              ></div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
};
export default SearchLeft;
