import http from "utils/request";

// 积分 ---- start ----
// 获取积分渠道状态
export const GetMemberPointsStatus = (params = {}) => {
  return http.get("/api/member/get_member_points_status", { params });
};

// 用户签到
export const SignIn = (params = {}) => {
  return http.post("/api/member/sign_in", params);
};

// 浏览商品获取积分
export const BrowseGoods = (params = {}) => {
  return http.post("/api/member/browse_goods", params);
};

// 邀请助力
export const PointsAssist = (params = {}) => {
  return http.post("/api/member/points_assist", params);
};

// 助力详情
export const PointsAssistDetail = (params = {}) => {
  return http.get("/api/member/points_assist_detail", { params });
};

// 积分 ---- end ----

// 抽奖 ---- start ----
// 获取抽奖活动信息
export const GetRedpackStatus = (params = {}) => {
  return http.get("/api/member/get_redpack_status", { params });
};

// 每天初始化红包个数
export const InitializationRedpack = (params = {}) => {
  return http.get("/api/member/initialization_redpack", { params });
};

// 抽奖
export const GetRedpack = (params = {}) => {
  return http.get("/api/member/get_redpack", { params });
};

// 抽奖 ---- end ----

// 获取ip弹窗情况
export const GetIpPopup = (params = {}) => {
  return http.get("/api/member/get_ip_popup", { params });
};

// 获取ip弹窗情况
export const SaveIpPopup = (params = {}) => {
  return http.post("/api/member/save_ip_popup", params);
};

// 红包申请提现
export const WithdrawRedpack = (params = {}) => {
  return http.post("/api/member/withdraw_redpack", params);
};

// 生成小程序推广链接
export const AssistXcxLink = (params = {}) => {
  return http.get("/api/member/assist_xcx_link", { params });
};
