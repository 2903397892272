import { useState, useEffect } from "react";
import { UnorderedListOutlined } from "@ant-design/icons";

// less
import "./index.less";

// api
import {Navigation,  GoodsSkuCount } from "api/home";

// module
import ClassBox from "pages/components/ClassBox";

const PageNav = (props) => {
  const { is_home, getClasses } = props;
  const [data, setData] = useState([]);
  const [skuCount, setSkuCount] = useState();

  useEffect(() => {
    const goodsSkuCount = async () => {
      const res = await GoodsSkuCount({});
      if (!res) return;
      if (res.ResponseStatus) return;
      setSkuCount(res.datas || 0);
      setTimeout(() => {
        goodsSkuCount();
      }, 60000);
    };

    const getNavigation = async () => {
      const res = await Navigation();
      if (res.ResponseStatus !== 0) return;
      setData(res?.datas || []);
      goodsSkuCount();
    };
    getNavigation();
  }, []);

  const goOther = (url, i) => {
    window.open(`${url}&ni=${i}`);
  };

  return (
    <div className="new_home-page-nav" style={{ borderBottomColor: is_home ? '#fff' : 'E5E5E5' }}>
      <div className="container">
        <ul className="page-navs">
          {is_home ? null : (
            <li className="page-nav plate-class">
              <UnorderedListOutlined className="fw700" />
              <span className="ml6">全部商品分类</span>
              <div className="class_box">
                <ClassBox getClasses={getClasses} />
              </div>
            </li>
          )}

          <li
            className={`page-nav ${is_home ? 'other-active' : ''}`}
            onClick={() => {
              window.open("/", 0);
            }}
          >
            <span>首页</span>
          </li>
          <li
            className="page-nav fw700"
            style={{ color: 'var(--main-color)' }}
            onClick={() => {
              window.open("/new_day?keywords=&order=6");
            }}
          >
            今日新品
            <div className="count">
              <span>{+skuCount === 0 ? "上新中" : +skuCount + "款"}</span>
            </div>
          </li>
          {data.map((k, i) => (
            <li
              key={i}
              className="page-nav skip-hot"
              style={{
                color: k.color || "",
                fontWeight: k.is_weight ? 700 : "",
              }}
              onClick={() => {
                goOther(k.url, i);
              }}
            >
              {k.navigation_name}
              {k.icon ? (
                <div className="skip-icon">
                  <img src={k.icon} style={{ height: 22 }} alt="" />
                </div>
              ) : null}
              {k.hot === 1 && !k.icon ? (
                <div className="skip-hot-icon">
                  <span>HOT</span>
                </div>
              ) : null}
              {k.is_new === 1 && !k.icon ? (
                <div className="skip-hot-icon">
                  <span>NEW</span>
                </div>
              ) : null}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
export default PageNav;
