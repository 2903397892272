import java_http from "utils/java_request";

// 我的积分
export const WalletPointsMyScore = (params = {}) => {
  return java_http.get("/wallet/points/myScore", { params });
};

// 积分明细  need_page: 1
export const WalletPointsDetail = (params = {}, type) => {
  return java_http.get(`/wallet/points/detail/${type}`, {
    params: { ...params, need_page: 1 },
  });
};

// 红包余额查询
export const WalletRedPacketBalance = (params = {}) => {
  return java_http.get("/wallet/redPacket/balance", { params });
};

// 红包获取记录 need_page: 1
export const WalletRedPacketListAcquire = (params = {}) => {
  return java_http.get("/wallet/redPacket/listAcquire", {
    params: { ...params, need_page: 1 },
  });
};

// 用户提现记录 need_page: 1
export const WalletRedPacketListUserWithdraw = (params = {}) => {
  return java_http.get("/wallet/redPacket/listUserWithdraw", {
    params: { ...params, need_page: 1 },
  });
};

// 红包中奖记录
export const WalletRedPacketListWinning = (params = {}) => {
  return java_http.get("/wallet/redPacket/listWinning/10", { params });
};

// 红包中奖记录
export const WalletRedPacketAcquiredInToday = (params = {}) => {
  return java_http.get("/wallet/redPacket/acquiredInToday", { params });
};

// 获取用户全部的可抽奖机会
export const WalletRedPacketFullChances = (params = {}) => {
  return java_http.get("/wallet/redPacket/fullChances", { params });
};