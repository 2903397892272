// less
import "./index.less";

// module
import Banner from "./Banner";
import MainBottom from "../../components/MainBottom";

const Module6 = (props) => {
  const { datas } = props;

  const templateFn = (key) => {
    return (datas[key] || { template_common: [] })?.template_common || [];
  };
  return (
    <div className="new_home-module6">
      <div className="module6-top">
        <div className="module6-right">
          {templateFn("right")?.map?.((k, i) => (
            <div className="module6-right-item" key={i}>
              <Banner data={k.value || []} />
              <div className="template-info flex-center">
                <div className="store-name">
                  <span>{k.store_name}</span>
                </div>
                <div className="template-name owt">{k.template_name}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="new_home_main_bottom">
        <MainBottom data={templateFn("main_bottom_left")} />
        <MainBottom data={templateFn("main_bottom_right")} />
      </div>
    </div>
  );
};
export default Module6;
