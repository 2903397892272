import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Affix } from "antd";
import LazyLoad from "react-lazyload";

// css
import "./index.less";

// util
import Storage from "utils/storage";

// api
import { GetNewRecommendConfig } from "api/home";

// module
import Nav from "pages/components/Nav";
import Header from "pages/components/Header";
import PageNav from "pages/components/PageNav";
import Bottom from "pages/components/Bottom";
import ClassBox from "pages/components/ClassBox";

import SwiperTop from "./components/SwiperTop";
import FixLeft from "./components/FixLeft";
import FixRight from "./components/FixRight";
import SwiperBanner from "./components/SwiperBanner";
import BigBanner from "./components/BigBanner";
import SmallBanner from "./components/SmallBanner";
import RecommendGoods from "./components/RecommendGoods";
import NewShop from "./components/NewShop";
import MainBottom from "./components/MainBottom";
import ShopRank from "./components/ShopRank";
import WealthRaffle from './components/WealthRaffle'

import LeftAffix from "./components/LeftAffix";
import Module1 from "./module/Module1";
import Module2 from "./module/Module2";
import Module3 from "./module/Module3";
import Module4 from "./module/Module4";
import Module5 from "./module/Module5";
import Module6 from "./module/Module6";
import Module7 from "./module/Module7";
import Module8 from "./module/Module8";
import Module9 from "./module/Module9";
import Module10 from "./module/Module10";
import Module11 from "./module/Module11";
import ModuleUpload from "./module/ModuleUpload";


// img
import zyt_logo from "assets/new_home/zyt_logo.png";
import template_star from "assets/new_home/template_star.png";


const NewHome = () => {
  const history = useHistory();
  const [member, setMember] = useState({});

  const [datasOther, setDatasOther] = useState([]);
  const [dataMap, setDataMap] = useState(new Map());

  // 广告请求-模块
  const getRecommendConfigModule = async () => {
    const res = await GetNewRecommendConfig({});
    if (!res) return;
    if (res.ResponseStatus) return;
    const arr = res?.datas || [];
    const other_datas = [];
    const map = new Map();
    arr.forEach((k) => {
      if (k.key === "other") {
        other_datas.push(k);
      } else {
        map.set(k.key, k);
      }
    });
    setDataMap(map);
    setDatasOther(other_datas);
  };

  useEffect(() => {
    // 其他请求
    const getInit = () => {
      if (document?.body?.scrollTop || document?.documentElement?.scrollTop) {
        document.body.scrollIntoView({ block: "start" });
      }
      getRecommendConfigModule();
    };

    // 广告请求-首屏
    const getRecommendConfig = async () => {
      const res = await GetNewRecommendConfig({
        keys: "top,main_top,fix_left,fix_right,big_banner,small_banner,recommend_goods,new_shop",
      });
      if (!res) return;
      if (res.ResponseStatus) return;
      const arr = res?.datas || [];
      const map = new Map();
      arr.forEach((k) => {
        if (k.key !== "other") {
          map.set(k.key, k);
        }
      });
      setDataMap(map);

      getInit();
    };

    document.title = `首页—找源头-叠石桥网销导航，直播选货供货平台，找家纺网销货源`;

    getRecommendConfig();
  }, []);

  const dataFn = (key) => {
    const arr = (dataMap?.get?.(key) || {})?.template_common || [];
    return arr;
  };

  const goOut = () => {
    Storage.removeUser();
    Storage.removeKS();
    Storage.removeDY();
    window.location.replace("/");
  };

  return (
    <div className="new_home">
      <SwiperTop data={dataFn("top")} />

      <Nav getMember={setMember} />
      <Affix offsetTop={0}>
        <Header />
      </Affix>
      <PageNav is_home={true} />

      {/* 顶部轮播 */}
      <SwiperBanner data={dataFn("main_top")} />

      {/* 左侧栏目 */}
      <FixLeft data={dataFn("fix_left")} />
      {/* 右侧栏目 */}
      <FixRight data={dataFn("fix_right")} />

      <div className="container flex">
        <ClassBox dataMap={dataMap} />
        <div className="new_home-banner-main">
          <BigBanner data={dataFn("big_banner")} />
          <SmallBanner data={dataFn("small_banner")} />
        </div>
        <div className="new_home-banner-right">
          <div className="new_home-login">
            <img className="zyt_logo" src={zyt_logo} alt="" />
            <div className="Hi">
              Hi, {member.member_name || "欢迎来到找源头家纺"}
            </div>
            {!member.token ? (
              <div className="flex-center flex-centerY">
                <div
                  className="btn1"
                  onClick={() => {
                    history.push("/login");
                  }}
                >
                  登录
                </div>
                <div
                  className="btn2"
                  onClick={() => {
                    history.push("/register");
                  }}
                >
                  注册
                </div>
              </div>
            ) : (
              <div className="Hi _999 pointer hover-red" onClick={goOut}>
                退出登录
              </div>
            )}
          </div>

          <div className="recommend_goods">
            <div className="title flex-center">
              <span className="iconfont icon-good"></span>
              <span>好货推荐</span>
            </div>
            <RecommendGoods data={dataFn("recommend_goods")} />
          </div>

          <div className="new_shop">
            <div className="title flex-center">
              <span className="iconfont icon-new"></span>
              <span>新进商家</span>
            </div>
            <NewShop data={dataFn("new_shop")} />
          </div>
        </div>
      </div>

      <div className="new_home_main_bottom">
        <MainBottom data={dataFn("main_bottom_left")} />
        <MainBottom data={dataFn("main_bottom_right")} />
      </div>

      <LeftAffix template={datasOther}></LeftAffix>
      <div className="new_home-others">
        {datasOther?.map?.((k, i) => (
          <div className="new_home-other" key={i} id={`d${i}`}>
            <div className="new_home-other-title">
              <div className="new_home-other-title-line"></div>
              <img src={template_star} alt="" />
              <span className="title">{k.title}</span>
              <img src={template_star} alt="" />
              <div className="new_home-other-title-line"></div>
            </div>
            {k.type === "module1" ? (
              <LazyLoad height={200} offset={100}>
                <Module1 datas={k} />
              </LazyLoad>
            ) : null}
            {k.type === "module2" ? (
              <LazyLoad height={200} offset={100}>
                <Module2 datas={k} />
              </LazyLoad>
            ) : null}
            {k.type === "module3" ? (
              <LazyLoad height={200} offset={100}>
                <Module3 datas={k} />
              </LazyLoad>
            ) : null}
            {k.type === "module4" ? (
              <LazyLoad height={200} offset={100}>
                <Module4 datas={k} />
              </LazyLoad>
            ) : null}
            {k.type === "module5" ? (
              <LazyLoad height={200} offset={100}>
                <Module5 datas={k} />
              </LazyLoad>
            ) : null}
            {k.type === "module6" ? (
              <LazyLoad height={200} offset={100}>
                <Module6 datas={k} />
              </LazyLoad>
            ) : null}
            {k.type === "module7" ? (
              <LazyLoad height={200} offset={100}>
                <Module7 datas={k} />
              </LazyLoad>
            ) : null}
            {k.type === "module8" ? (
              <LazyLoad height={200} offset={100}>
                <Module8 datas={k} />
              </LazyLoad>
            ) : null}
            {k.type === "module9" ? (
              <LazyLoad height={200} offset={100}>
                <Module9 datas={k} />
              </LazyLoad>
            ) : null}
            {k.type === "module10" ? (
              <LazyLoad height={200} offset={100}>
                <Module10 datas={k} />
              </LazyLoad>
            ) : null}
            {k.type === "module11" ? (
              <LazyLoad height={200} offset={100}>
                <Module11 datas={k} />
              </LazyLoad>
            ) : null}
          </div>
        ))}

        <LazyLoad height={200} offset={100}>
          <ShopRank />
        </LazyLoad>

        <LazyLoad height={200} offset={100}>
          <ModuleUpload />
        </LazyLoad>
      </div>

      <LazyLoad height={200} offset={100}>
        <Bottom />
      </LazyLoad>

      {/* 活动 */}
      <WealthRaffle member={member} />
    </div>
  );
};
export default NewHome;
