import { useEffect, useState, Suspense } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Routers from "config/route";

// css
import "./assets/css/common.less";

// util
import Storage from "utils/storage";

// api
import { setting, daylogin } from "api/login";
import { AddDataOverview } from "api/home";

// module
import Home from "pages/new_home";
import Merchant from "pages/merchant/merchant";
import RightAffix from "pages/components/RightAffix";
import H5 from "pages/h5/h5";

import "core-js/es";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import "@babel/polyfill";

function App() {
  const [pathName, setPathName] = useState("");

  // 监听路由变化 ---- start ----
  /**
   * 重写history的pushState和replaceState
   * @param action pushState|replaceState
   * @return {function(): *}
   */
  function wrapState(action) {
    let raw = window.history[action];
    return function () {
      let wrapper = raw.apply(this, arguments);
      let e = new Event(action);
      e.stateInfo = { ...arguments };
      window.dispatchEvent(e);
      return wrapper;
    };
  }

  //修改原始定义
  window.history.pushState = wrapState("pushState");
  window.history.replaceState = wrapState("replaceState");
  window.addEventListener("pushState", function (e) {
    setPathName(e?.stateInfo?.[2]);
  });
  window.addEventListener("replaceState", function (e) {
    setPathName(e?.stateInfo?.[2]);
  });
  window.addEventListener("popstate", function () {
    setPathName(window?.location?.pathname);
  });
  // 监听路由变化 ---- end ----

  // 配置项初始化
  useEffect(() => {
    const getData = async () => {
      const json = Storage?.getUser?.();
      const form = JSON.parse(json);
      const time = Storage?.getTIME?.();
      if (form?.token && time) {
        if (time < +new Date() - 86400000) {
          const res = await daylogin({
            member_id: +JSON.parse(Storage.getUser()).member_id,
          });
          if (!res) return;
          if (res.ResponseStatus) return;
          Storage.setTIME(+new Date());
        }
      }

      const set_join = Storage.getSetting();
      const set_form = JSON.parse(set_join);
      if (set_form?.time > +new Date() - 1000 * 1) return;
      const res = await setting({});
      const new_form = { ...(res?.datas || {}), time: +new Date() };
      const new_json = JSON.stringify(new_form);
      Storage.setSetting(new_json);
    };
    getData();
  }, []);

  // 检测是否PC端访问
  const IsPC = () => {
    const userAgentInfo = navigator.userAgent;
    const Agents = [
      "Android",
      "iPhone",
      "iphone os",
      "midp",
      "rv:1.2.3.4",
      "ucweb",
      "SymbianOS",
      "windows ce",
      "Windows Phone",
      "windows mobile",
      "iPad",
      "iPod",
      "Vivo",
      "vivo",
      "Nokia",
    ];
    let flag = true;
    for (let v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        flag = false;
        break;
      }
    }
    return flag;
  };

  // 页面数据监听
  useEffect(() => {
    window.StartTime = +new Date();
    let fn = () => {
      if (document.visibilityState === "visible") {
        setTimeout(() => {
          window.StartTime = +new Date();
        }, 60);
      } else {
        window.EndTime = +new Date();
        AddDataOverview({
          visitor_id: window.visitorId,
          start_time: +(window.StartTime / 1000)?.toFixed(0),
          end_time: +(window.EndTime / 1000)?.toFixed(0),
          duration: +((window.EndTime - window.StartTime) / 1000)?.toFixed(0),
          page_name: window.location?.pathname?.split?.("/")?.[1],
          searchs: window.location?.pathname?.split?.("/")?.[2],
          channel: 1,
        });
      }
    };
    document.addEventListener("visibilitychange", fn);
    window.onbeforeunload = fn;
    return () => {
      document.removeEventListener("visibilitychange", fn);
    };
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <Suspense
          fallback={
            <div className="textC" style={{ paddingTop: 200 }}>
              页面加载中...
            </div>
          }
        >
          <BrowserRouter>
            <Switch>
              <Route
                path="/"
                exact
                component={IsPC() ? (global.private ? Merchant : Home) : H5}
              />
              {Routers.map((item, index) => (
                <Route
                  key={index}
                  path={item.path}
                  exact
                  render={(props) =>
                    !item.auth ? (
                      <Route
                        path={item.path}
                        component={IsPC() ? item.component : H5}
                      />
                    ) : JSON.parse(Storage.getUser()) ? (
                      <Route
                        path={item.path}
                        component={IsPC() ? item.component : H5}
                      />
                    ) : (
                      <Redirect
                        to={{
                          pathname: IsPC() ? "/login" : H5,
                          state: { from: props.location },
                        }}
                      />
                    )
                  }
                />
              ))}
              <Redirect to="/" />
            </Switch>
          </BrowserRouter>
        </Suspense>
      </header>

      {!["/login"]?.includes?.(pathName) ? <RightAffix /> : null}
    </div>
  );
}

export default App;
