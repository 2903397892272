import { useEffect, useLayoutEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

import "./index.less";

import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper/core";
SwiperCore.use([Autoplay, Pagination, Navigation]);

const SwiperBanner = (props) => {
  const { data = [] } = props;
  const [swiper, setSwiper] = useState(2500);
  useEffect(() => {
    if (swiper && data?.length > 1) {
      const index = Math.floor(Math.random() * data?.length);
      swiper?.slideToLoop?.(index);
      setTimeout(() => {
        swiper?.autoplay?.start?.();
      }, 200);
    }
  }, [swiper, data.length]);

  const onMouseEnter = () => {
    if (data?.length === 1) return;
    swiper.autoplay.stop();
  };
  const onMouseLeave = () => {
    if (data?.length === 1) return;
    swiper.autoplay.start();
  };

  const [top, setTop] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      setTop(document.documentElement.scrollTop);
    }
    window.addEventListener("scroll", updateSize);
    updateSize();
    return () => window.removeEventListener("scroll", updateSize);
  }, []);

  return (
    <div
      className="new_home-fix-left"
      style={{
        position: top > 146 ? "fixed" : "absolute",
        top: top > 146 ? 140 : 290,
        right: top > 640 ? "calc(50% + 680px)" : "calc(50% + 620px)",
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {data?.length === 1 ? (
        <div
          className={
            data?.[0]?.template_url ? "pointer swiper-slide" : "swiper-slide"
          }
          style={{
            height: "100%",
            background: `url(${data?.[0]?.template_img}) center`,
            backgroundSize: "100%",
          }}
          onClick={() => {
            if (!data?.[0]?.template_url) return;
            window.open(data?.[0]?.template_url);
          }}
        ></div>
      ) : (
        <Swiper
          slidesPerView={1}
          spaceBetween={1}
          loop
          loopAdditionalSlides={2}
          effect={"fade"}
          loopedSlides={3}
          autoplay={{ delay: 3000 }}
          className="mySwiper"
          onSwiper={setSwiper}
        >
          {data?.map?.((k, i) => (
            <SwiperSlide key={i}>
              <div
                className={k.template_url ? "pointer" : ""}
                style={{
                  height: "100%",
                  background: `url(${k.template_img}) center`,
                  backgroundSize: "100%",
                }}
                onClick={() => {
                  if (!k.template_url) return;
                  window.open(k.template_url);
                }}
              ></div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
};
export default SwiperBanner;
