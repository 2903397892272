// import { useState } from "react";
// import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, {
  Autoplay,
  Pagination,
  Navigation,
  EffectFade,
} from "swiper/core";

// css
import "./index.less";
import "swiper/swiper.min.css";
import "swiper/components/effect-fade/effect-fade.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

// module
import MainBottom from "../../components/MainBottom";

SwiperCore.use([Autoplay, Pagination, Navigation, EffectFade]);

const Module9 = (props) => {
  const { datas } = props;
  // const [swiper, setSwiper] = useState(2500);

  // const onMouseEnter = (i) => {
  //   swiper.autoplay.stop();
  // };
  // const onMouseLeave = () => {
  //   swiper.autoplay.start();
  // };

  const templateFn = (key) => {
    return (datas[key] || { template_common: [] })?.template_common || [];
  };

  return (
    <div className="new_home-module9">
      {/* {templateFn("right")?.length === 1 ? ( */}
        <div className="mySwiper">
          {templateFn("right")?.map?.((k, i) => (
            <div className="mySwiper-item" key={i}>
              <div
                className="box-top"
                onClick={() => {
                  window.open(k?.template_url);
                }}
              >
                <img src={k.template_img} alt="" />
              </div>
              <div className="box-items">
                {k.value?.map?.((k1, i1) => (
                  <div
                    className="box-item"
                    key={i * 10 + i1}
                    onClick={() => {
                      window.open(k1?.template_url);
                    }}
                  >
                    <div className="images">
                      <img src={k1?.template_img} alt="" />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      {/* ) : (
        templateFn("right")?.map?.((k, i) => (
          <Swiper
            slidesPerView={1}
            spaceBetween={1}
            loop
            loopAdditionalSlides={2}
            loopedSlides={3}
            autoplay
            pagination={true}
            navigation={true}
            className="mySwiper"
            onSwiper={setSwiper}
          >
            <SwiperSlide
              key={i}
              onMouseEnter={() => {
                onMouseEnter(i);
              }}
              onMouseLeave={() => {
                onMouseLeave();
              }}
            >
              <div className="mySwiper-item">
                <div
                  className="box-top"
                  onClick={() => {
                    window.open(k?.template_url);
                  }}
                >
                  <img src={k.template_img} alt="" />
                </div>
                <div className="box-items">
                  {k.value?.map?.((k1, i1) => (
                    <div
                      className="box-item"
                      key={i * 10 + i1}
                      onClick={() => {
                        window.open(k1?.template_url);
                      }}
                    >
                      <div className="images">
                        <img src={k1?.template_img} alt="" />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        ))
      )} */}

      {templateFn("main_bottom_left")?.length ||
      templateFn("main_bottom_right")?.length ? (
        <div className="new_home_main_bottom">
          <MainBottom data={templateFn("main_bottom_left")} />
          <MainBottom data={templateFn("main_bottom_right")} />
        </div>
      ) : null}
    </div>
  );
};
export default Module9;
