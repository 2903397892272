// less
import "./index.less";

// module
import MainBottom from "../../components/MainBottom";

const Module7 = (props) => {
  const { datas } = props;

  const templateFn = (key) => {
    return (datas[key] || { template_common: [] })?.template_common || [];
  };
  return (
    <div className="new_home-module7">
      <div className="module7-top">
        <div className="module7-right">
          {templateFn("right")?.map?.((k, i) => (
            <div className="module7-right-item pointer" key={i}>
              <div
                className="image"
                onClick={() => {
                  window.open(k.template_url);
                }}
              >
                <img src={k.template_img} alt="" />
              </div>
              <div className="template-items">
                {k.value?.map?.((k1, i1) => (
                  <div
                    className="template-item"
                    key={i1}
                    onClick={() => {
                      window.open(k1.template_url);
                    }}
                  >
                    <div className="img">
                      <img src={k1.template_img} alt="" />
                    </div>
                    <div className="template-info">
                      <div className="store-name">{k1.store_name}</div>
                      <div className="template-name owt">
                        {k1.template_name}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="new_home_main_bottom">
        <MainBottom data={templateFn("main_bottom_left")} />
        <MainBottom data={templateFn("main_bottom_right")} />
      </div>
    </div>
  );
};
export default Module7;
