import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { message } from "antd";

// css
import "./index.less";

// util
import store from "store";
import dayjs from "dayjs";

// api
import { AddEvent } from "api/common";
import {
  WalletRedPacketFullChances,
  WalletRedPacketAcquiredInToday,
} from "api/java/wealth";
import { GetRedpackStatus, GetRedpack } from "api/wealth";

// img
import wealth_raffle_turn_icon1 from "assets/wealth/wealth_raffle_turn_icon1.png";
import wealth_raffle_turn_icon2 from "assets/wealth/wealth_raffle_turn_icon2.png";
import wealth_raffle_turn_icon3 from "assets/wealth/wealth_raffle_turn_icon3.png";

import red_envelope_icon1 from "assets/wealth/red_envelope_icon1.png";
import integral_icon1 from "assets/wealth/integral_icon1.png";
import wealth_main_del_icon from "assets/wealth/wealth_main_del_icon.png";

const clipPath = {
  2: "polygon(0% 0%, 100% 0%, 100% 50%, 0% 50%)",
  4: "polygon(50% 50%, 0% 0%, 100% 0%)",
  6: "polygon(50% 50%, 21.1% 0%, 78.9% 0%)",
  8: "polygon(50% 50%, 29% 0%, 71% 0%)",
  10: "polygon(50% 50%, 33.6% 0%, 66.4% 0%)",
  12: "polygon(50% 50%, 36.78% 0%, 63.22% 0%)",
  14: "polygon(50% 50%, 38.4% 0%, 61.6% 0%)",
};
const msg_code = {
  SUCCESS: "成功",
  SUCCESS_TICKET: "消耗 0 积分可获得一次抽奖机会",
  SUCCESS_POINT: "使用积分抽奖成功",
  PARAM_INVALID: "参数无效",
  DAY_QUOTA_LIMIT: "今日红包已发完，明天继续。",
  ACCOUNT_FROZEN: "账户已冻结",
  POINT_NOT_ENOUGH: "积分不足，继续赚积分~",
  DAY_CHANGE_NOT_ENOUGH: "当天抽奖机会已用完，明天继续~",
  POINT_STATE_ERROR: "积分状态异常，请重试",
  POINT_ACTIVITY_NOT_BEGIN: "活动未开启",
  RED_PACKET_TEMPLATE_ERROR: "非法红包",
};
const WealthRaffle = (props) => {
  const { member } = props;
  const history = useHistory();
  const [isStart, setIsStart] = useState(0);

  // 弹窗IP ---- start ----
  const [isIp, setIsIp] = useState(false);
  useEffect(() => {
    const getIpPopup = async () => {
      const time = store.get("activity-time") || 0;
      const start_time = dayjs().startOf("day").valueOf();
      if (time < start_time) {
        const now_time = +new Date();
        store.set("activity-time", now_time);
        setIsIp(true);
      }
    };
    if (isStart) getIpPopup();
  }, [isStart]);
  const saveIpPopup = async () => {
    AddEvent({ even_name: "首页-助力弹窗-【关闭】" });
    setIsIp(false);
  };
  // 弹窗IP ---- end ----

  // 获取今日已抽奖次数
  const [count, setCount] = useState(3);
  const walletRedPacketAcquiredInToday = async () => {
    const res = await WalletRedPacketAcquiredInToday({});
    setCount(res?.total || 0);
  };

  // 获取抽奖次数
  const [fullChances, setFullChances] = useState(0);
  const getWalletRedPacketFullChances = async () => {
    const res = await WalletRedPacketFullChances();
    setFullChances(res?.total || 0);
  };
  useEffect(() => {
    if (member?.token && isIp) {
      getWalletRedPacketFullChances();
      walletRedPacketAcquiredInToday();
    }
  }, [member.token, isIp]);

  // 获取初始化红包
  const [initPacks, setInitPacks] = useState([]);
  const getRedpackStatus = async () => {
    const res = await GetRedpackStatus();
    setInitPacks(res?.datas?.worth_list || []);
    setIsStart(res?.datas?.is_start || 0);
  };
  useEffect(() => {
    getRedpackStatus();
  }, []);

  // 抽奖
  const [packIDIndex, setPackIDIndex] = useState(0);
  const [raffleStart, setraffleStart] = useState(false);
  const [worthText, setWorthText] = useState("");
  const raffleBeforeTimer = useRef();
  const raffleTimer = useRef();
  const handleRaffleBefore = (index) => {
    setraffleStart(true);
    if (raffleStart) return;
    clearTimeout(raffleBeforeTimer.current);
    raffleBeforeTimer.current = setTimeout(() => {
      handleRaffle(index);
      setPackIDIndex(index);
    }, 60);
  };
  const handleRaffle = (index) => {
    clearTimeout(raffleTimer.current);
    const time = 4800;
    raffleTimer.current = setTimeout(() => {
      setraffleStart(false);
      if (index === -1) return;
      const worth = initPacks[index].worth || 0;
      const worth_text = worth ? worth + "元" : "感谢参与";
      setFullChances((count) => {
        return count - 1;
      });
      setCount((count) => {
        return count + 1;
      });
      window?.walletRedPacketAcquiredInToday?.()
      window?.getWalletRedPacketFullChances?.()
      setWorthText(worth_text);
      setTimeout(() => {
        setWorthText("");
      }, 2900);
    }, time);
  };

  // 抽奖
  const [redPackStart, setRedPackStart] = useState(false);
  const redPackStartTimer = useRef();
  const getRedpackBefore = () => {
    AddEvent({
      even_name: "欢乐转盘抽现金-【抽奖】",
    });
    setRedPackStart(true);
    if (redPackStart) return;
    clearTimeout(redPackStartTimer.current);
    redPackStartTimer.current = setTimeout(() => {
      getRedpack();
    }, 60);
  };
  const getRedpack = async () => {
    const res = await GetRedpack();
    setRedPackStart(false);
    if (!res) return;
    if (res.ResponseStatus)
      return message.error(msg_code[res.ResponseCode] || res.ResponseMsg);
    if (res.ResponseCode === "SUCCESS_TICKET")
      message.success("消耗 0 积分可获得一次抽奖机会");
    const id = res?.datas?.raffle_extend_id || 0;
    const index = initPacks?.findIndex?.((k) => k.raffle_extend_id === id);
    setTimeout(() => {
      handleRaffleBefore(index);
    }, 60);
  };
  return (
    <div>
      {isIp ? (
        <div className="wealth-home">
          {/* 转盘 */}
          <div className="wealth-home-main">
            <div className="wealth-raffle-turntable-title"></div>
            <div className="wealth-raffle-turntable-box">
              <div className="wealth-raffle-turntable no-select">
                <div
                  className={`turntable-box ${
                    raffleStart ? "turntable-start" : ""
                  }`}
                  style={{
                    transform: `rotate(${
                      raffleStart
                        ? 6 +
                          (initPacks?.length - packIDIndex) / initPacks?.length
                        : (initPacks?.length - packIDIndex) / initPacks?.length
                    }turn)`,
                  }}
                >
                  <div className="turntable-points">
                    {[1, 2, 3, 4, 5, 6, 7, 8]?.map?.((k) => (
                      <div
                        className={`turntable-point turntable-point${k}`}
                        key={k}
                      />
                    ))}
                  </div>
                  <div className="turntable-items-box">
                    <div className="turntable-items">
                      {initPacks?.map?.((k, i) => (
                        <div
                          key={k.raffle_extend_id}
                          className="turntable-item"
                          style={{
                            transform: `rotate(${
                              i * (360 / (initPacks?.length || 1))
                            }deg)`,
                            clipPath: clipPath[initPacks?.length],
                          }}
                        >
                          <div>{k.worth ? k.name : "感谢参与"}</div>
                          <img
                            src={
                              k.worth
                                ? i % 2
                                  ? wealth_raffle_turn_icon2
                                  : wealth_raffle_turn_icon3
                                : wealth_raffle_turn_icon1
                            }
                            style={{ width: 78 }}
                            alt=""
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div
                  className="turntable-btn"
                  onClick={getRedpackBefore}
                  style={{
                    paddingTop:
                      (fullChances && 3 - count) || !member?.token ? 80 : 92,
                  }}
                >
                  <div className="turntable-text no-select">抽奖</div>
                  {fullChances && 3 - count ? (
                    <div className="count-text">
                      今日可抽奖
                      {fullChances > 3 - count ? 3 - count : fullChances}次
                    </div>
                  ) : !member?.token ? (
                    <div className="count-text">新老用户可抽奖一次</div>
                  ) : null}
                  {fullChances && 3 - count ? (
                    <div className="count">
                      {fullChances > 3 - count ? 3 - count : fullChances}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="wealth-raffle-turntable-bottom">
              <div
                className="wealth-raffle-turntable-bottom-btn"
                onClick={() => {
                  history.push("/wealth");
                }}
              >
                <img src={integral_icon1} alt="" />
                <span>去赚积分</span>
              </div>
              <div
                className="wealth-raffle-turntable-bottom-btn"
                onClick={() => {
                  history.push("/wealth", { active: 2 });
                }}
              >
                <img src={red_envelope_icon1} alt="" />
                <span>无门槛提现</span>
              </div>
            </div>

            <div className="wealth-home-main-colse" onClick={saveIpPopup}>
              <img src={wealth_main_del_icon} alt="" />
            </div>
          </div>

          {/* 中奖特效 */}
          {worthText ? (
            <div className="wealth-red-box">
              <div className="wealth-red-main">
                <span className="wealth-red-text">{worthText}</span>
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
export default WealthRaffle;
