import { useEffect, useState } from "react";
import { Image } from "antd";

// utils
import Storage from "utils/storage";

// css
import "./index.less";

// img
import img1 from "assets/imgs/relation6.png";
import img2 from "assets/imgs/relation5.png";
import img3 from "assets/imgs/relation4.png";

const Bottom = () => {
  // 获取基础配置参数 ---- start ----
  const [data, setData] = useState({});
  useEffect(() => {
    const getInit = () => {
      const set_json = Storage?.getSetting?.();
      if (set_json) {
        const form = JSON.parse(set_json);
        setData(form);
      }
    };
    getInit();
  }, []);
  // 获取基础配置参数 ---- end ----

  const goArticle = (i, index, type, url) => {
    if (type === 1) {
      window.open(`/article/${i}/${index}`);
    } else if (type === 2) {
      window.open(url);
    }
  };
  const goArticle1 = (k) => {
    if (k.type === 1) {
      window.open(`/article/-1/-1?id=${k.id}`);
    } else if (k.type === 2) {
      window.open(k.content);
    }
  };
  return (
    <div className="new_home-bottom">
      <div className="container">
        {global.private ? null : (
          <div className="intro flex-b">
            <div className="erw flex">
              <div>
                <Image
                  width={150}
                  preview={false}
                  src={data.mall_wx_logo}
                ></Image>
                <p className="fw700 fz18 _666 mtop10">官方微信小程序</p>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <Image
                  width={150}
                  preview={false}
                  src={data.mall_gzh_logo}
                ></Image>
                <p className="fw700 fz18 _666 mtop10">官方微信公众号</p>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <Image
                  width={150}
                  preview={false}
                  src={data.mall_app_logo}
                ></Image>
                <p className="fw700 fz18 _666 mtop10">官方App</p>
              </div>
            </div>
            <ul className="flex-b">
              {data.article_class &&
                data.article_class.map((k, i) => (
                  <li
                    className="mr20"
                    key={i}
                    style={{ display: i > 4 ? "none" : "", width: "20%" }}
                  >
                    <div className="title">{k.class_name}</div>
                    <ol className="_ccc">
                      {k.article.map((item, index) => (
                        <li
                          key={index}
                          className="hover-red pointer"
                          onClick={() => {
                            goArticle(i, index, item.type, item.content);
                          }}
                        >
                          {item.title}
                        </li>
                      ))}
                    </ol>
                  </li>
                ))}
            </ul>
          </div>
        )}
        <div className="introd container">
          {global.private ? null : (
            <ul className="aboutUs flex flex-centerY">
              {data.bottom_article &&
                data.bottom_article.map((k, i) => (
                  <div className="flex" key={i}>
                    {i !== 0 ? <li className="lined"></li> : null}
                    <li
                      className="hover-red pointer"
                      onClick={() => {
                        goArticle1(k);
                      }}
                    >
                      {k.title}
                    </li>
                  </div>
                ))}
            </ul>
          )}
          <div className="paytype flex-centerY">
            <Image width={95} preview={false} src={img1}></Image>
            <Image width={95} preview={false} src={img2}></Image>
            <Image width={95} preview={false} src={img3}></Image>
          </div>
          <div
            className="company fz12 _999"
            dangerouslySetInnerHTML={{ __html: data.bottom }}
          />
        </div>
      </div>
    </div>
  );
};
export default Bottom;
